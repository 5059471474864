<template>
  <div id="app">
    <SaleBar />
    <Header />
    <router-view />
    <Footer />
  </div>
</template>


<script>
import './assets/css/style.css'
import SaleBar from './components/ui/SaleBar.vue'
import Header from './components/ui/Header.vue'
import Footer from './components/ui/Footer.vue'

export default {
  components: {SaleBar, Header, Footer},
  data(){
    return{

    }
  },
  created(){
    (async () => {
    const promises = [
        'posts',
        'comments',
        'users',
    ].map(async (method) => (await fetch(`https://jsonplaceholder.typicode.com/${method}`)).json())

    const [
        posts,
        comments,
        users,
    ] = await Promise.all(promises)

    // Преобразование

    // console.log(comments)
    const  newArray = []
    posts.map((item) => {
      const obj = {
        id: item.id,
        title: item.title,
        userName: users.find(i => i.id === item.userId).name,
        commentsCount: comments.filter(c => c.postId === item.id).length
      }
      newArray.push(obj)
    })
    console.log(newArray)

})();
    this.$store.dispatch('course/loadCats')
    this.$store.dispatch('content/loadNews')
    this.$store.dispatch('content/loadReviews')
  }
}
</script>
