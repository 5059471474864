<template>
    <div>
        <div class="row" v-if="cats.length">
            <div class="col-lg-4" v-for="(item, index) in cards" :key="index">
                <CourseCard :course="item" />
            </div>
        </div>
        <div class="row" v-if="cats.length && cards === null">
            <div class="col-lg-12 text-center">
                <h4 class="big-grey"><span class="mdi mdi-battery-low"></span> Знания в этом разделе пока отсутствуют</h4>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { API_URL } from '../config'
import CourseCard from '../components/ui/CourseCard.vue'

export default {
    props: ["cat"],
    components: {CourseCard},
    computed: {
        ...mapGetters({
            cats: "course/getCats"
        })
    },
    data(){
        return{
            cards: []
        }
    },
    watch: {
        cat(){
            this.getCatItems()
        },
        cats(){
            this.getCatItems()
        },
    },
    created(){
        if(this.cats.length){
            this.getCatItems()
        }
    },
    methods: {
        getCatItems(){
            let singleCat = this.cats.find(item =>{
                return item.slug === this.cat
            })
            this.cards = []

            axios.post(`${API_URL}wp-json/dms/v1/get/items/free`, {term_id: singleCat.term_id})
            .then(res =>{
                if(!res.data.length){
                    this.cards = null
                }else{
                    this.cards = res.data
                }
            })
        }
    }
}
</script>