<template>
    <div v-if="courseItem">
        <!-- <section id="inner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <p class="big-grey mb-0">{{courseItem.category.name}}</p>
                        <h1>{{courseItem.title}}</h1>
                        <p class="big-grey mb-0" v-if="courseItem.contains && courseItem.contains[0]"><span class="mdi mdi-movie-open-check blue"></span> {{courseItem.contains[0].content}}</p>
                        <p class="big-grey mb-0" v-if="courseItem.contains && courseItem.contains[1]"><span class="mdi mdi-chair-rolling blue"></span> {{courseItem.contains[1].content}}</p>
                        <p class="big-grey mb-0" v-if="courseItem.contains && courseItem.contains[2]"><span class="mdi mdi-comment-text-outline blue"></span> {{courseItem.contains[2].content}}</p>
                        <p class="big-grey mb-36" v-if="courseItem.contains && courseItem.contains[3]"><span class="mdi mdi-web-check blue"></span> {{courseItem.contains[3].content}}</p>
                        <button class="blue-btn">Купить за {{courseItem.price}} <span class="mdi mdi-currency-rub"></span></button>
                       
                    </div>
                    <div class="col-lg-6">
                        <img :src="courseItem.image" class="">
                    </div>
                </div>
            </div>
        </section> -->
        
        <section id="main">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <img src="@/assets/img/starticon.svg" class="starticon">
                        <h1 style="margin-bottom:10px;">Как построить beauty-бизнес, приносящий от</h1>
                        <h3 class="mb-36"><span style="font-size:32px; color:#316AFF;line-height:30px!important;">300.000 до 1.000.000+ <br> в месяц стабильно</span></h3>
                        
                        <ul class="ps-3 mb-4">
                            <li><p>Простая система, которая поможет твоему бизнесу вырасти  до х10 раз в год </p></li>
                            <li><p>уже более 200 beauty-мастеров открыли свой бизнес под моим руководством и зарабатывают от 300.000 до 5 млн в месяц+</p></li>
                        </ul>

                        <button class="blue-btn">Записаться</button>
                    </div>
                </div>
                <div class="start-img"></div>
            </div>
        </section>

        <section id="usual" class="pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>Как это работает?</h2>
                        <p class="black-txt">
                            Это обучение, в котором ты не только станешь крутым управленцем и будешь знать, как работает маркетинг, экономика и другие сферы бизнеса. <br> Ты дойдешь до поставленной цели через практическую работу с твоим бизнесом
                            <br><br>
                            <ul class="ps-3">
                                <li>Уроки в записи, которые можно смотреть в любое время</li>
                                <li>Практические задания, которые помогут отработать полученные знания сразу на своём бизнесе</li>
                                <li>Сообщество сильных руководителей, которые общаются и помогают друг-другу и после обучения</li>
                                <li>Моя постоянная поддержка в чатах</li>
                            </ul>
                            <br>
                            <button class="blue-btn">Посмотреть программу</button>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <CallToAction/>

        <section id="usual">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb-50">
                        <img src="@/assets/img/starticon.svg" class="mb-2">
                        <h2>Программа Курса</h2>
                        <div class="d-flex">
                            <p class="mb-1 me-4"><span class="mdi mdi-view-module blue"></span>    3 Модуля</p>
                            <p class="mb-1 me-4"><span class="mdi mdi-video-box blue"></span>    35 Видеоуроков</p>
                            <p class="mb-1 me-4"><span class="mdi mdi-pencil-box blue"></span>    20 практических заданий </p>
                            <p class="mb-1 me-4"><span class="mdi mdi-note-multiple blue"></span>    Более 20 дополнительных материалов</p>
                        </div>
                    </div>
                    <div class="col-lg-12 mb-36">
                        <h3 class="mb-4">Модуль 1. Управление</h3>
                        <hr>
                        <ul class="ps-3 mb-4">
                            <li>Как научиться руководить, если боишься спорить даже со своей младшей сестрой</li>
                            <li>Делегирование - как получать больше денег, а всю работу передать команде</li>
                            <li>Регламенты и договор, как инструменты мирного урегулирования конфликтов</li>
                            <li>Как мотивировать сотрудников, чтобы они приносили еще больше денег</li>
                            <li>Методы, стили управления, которые помогут тебе быть уважаемым, а не ненавистным руководителем </li>
                            <li>Где найти идеальных сотрудников, которые будут работать с вами долго </li>
                            <li>Как не наткнуться на халявщиков, воров и наркоманов, а отобрать в команду только крутые кадры</li>
                            <li>Кому сколько платить, как рассчитывать оплату труда</li>
                        </ul>
                        <p class="black-txt"><strong><span class="mdi mdi-star blue"></span>  Результат - Соберешь идеальную команду и научишься управлять ей так, чтобы бизнес работал и приносил деньги без твоего постоянного участия</strong></p>
                    </div>
                    <div class="col-lg-12 mb-36">
                        <h3 class="mb-4">Модуль 2. Маркетинг</h3>
                        <hr>
                        <ul class="ps-3 mb-4">
                            <li>Где искать клиентов?</li>
                            <li>Продукты и Услуги, за которые клиенты готовы заплатить любые деньги</li>
                            <li>Секреты продаж на высокие чеки</li>
                            <li>Как самостоятельно создавать рекламные компании, которые будут работать, а не сливать бюджет</li>
                            <li>Самые рабочие, проверенные способы привлечения клиентов как онлайн, так и офлайн</li>

                        </ul>
                        <p class="black-txt"><strong><span class="mdi mdi-star blue"></span> Результат - Ты научишься привлекать до 150+ клиентов в месяц, благодаря чему твой бизнес будет приносить высокий доход в любое время</strong></p>
                    </div>
                    <div class="col-lg-12 mb-36">
                        <h3 class="mb-4">Модуль 3. Финансы</h3>
                        <hr>
                        <ul class="ps-3 mb-4">
                            <li>Основные финансовые показатели  бизнеса (оборот, чистая прибыль, рентабельность, расходы...)</li>
                            <li>Планирование прибыли.</li>
                            <li>Работа с бюджетом -  Ты перестанешь сливать деньги непонятно куда и станешь зарабатывать минимум в 2 раза больше</li>
                            <li>Ценообразование простым языком</li>
                            <li>Определишь сколько нужно зарабатывать ежемесячно, чтобы никогда не выйти в минус  (точка безубыточности)</li>

                        </ul>
                        <p class="black-txt"><strong><span class="mdi mdi-star blue"></span> Результат - Определишь, куда сливаются деньги и как увеличить прибыль бизнеса в несколько раз</strong></p>
                    </div>
                </div>
            </div>
        </section>


        <section id="usual" class="pt-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="blue-box">
                            <div class="row">
                                <div class="row">
                                    <div class="col-lg-12 text-center">
                                         <h2>Дополнительные материалы стоимостью 100.000+</h2>
                                    </div>
                                    <div class="col-lg-3" v-for="(item, i) in additions" :key="i">
                                        <div class="d-flex align-start justify-start">
                                            <span class="mdi mdi-check blue mr-10"></span>
                                            <p>
                                                {{item}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="news">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2>Тарифы обучения</h2>
                    </div>
                    <div class="col-lg-4">
                        <div class="course-card">
                            <div class="course-card-body">
                                <div class="info">
                                    <p>Тариф</p>
                                </div>
                                <h3>“Я сам”</h3>
                                <p class="small-grey">
                                    35 Уроков в записи <br>
                                    20 дополнительных материалов <br>
                                    Доступ на 6 месяцев <br>
                                    Без проверки домашнего задания

                                </p>
                                <hr>
                                <div class="price">Стоимость - 64 990<span class="mdi mdi-currency-rub"></span></div>
                                <p class="black-txt">Рассрочка на 12 месяцев - <span class="blue">5 416<span class="mdi mdi-currency-rub"></span></span></p>
                                <button class="blue-btn">Записаться</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="course-card">
                            <div class="course-card-body">
                                <div class="info">
                                    <p>Тариф</p>
                                </div>
                                <h3>“С экспертом”</h3>
                                <p class="small-grey">
                                    35 Уроков в записи <br>
                                    20 дополнительных материалов <br>
                                    Доступ на 12 месяцев <br>
                                    Проверка домашних заданий спикером <br>
                                    Поддержка в закрытом чате курса с единомышленниками
                                </p>
                                <hr>
                                <div class="price">Стоимость - 74 990<span class="mdi mdi-currency-rub"></span></div>
                                <p class="black-txt">Рассрочка на 12 месяцев - <span class="blue">6 250<span class="mdi mdi-currency-rub"></span>/мес.</span></p>
                                <button class="blue-btn">Записаться</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="course-card">
                            <div class="course-card-body">
                                <div class="info">
                                    <p>Тариф</p>
                                </div>
                                <h3>“Макси”</h3>
                                <p class="small-grey">
                                    35 Уроков в записи <br>
                                    20 дополнительных материалов <br>
                                    Доступ на 12 месяцев <br>
                                    Проверка домашних заданий спикером <br>
                                    Поддержка в закрытом чате курса с единомышленниками <br>
                                    Блок уроков по психологии
                                </p>
                                <hr>
                                <div class="price">Стоимость - 84 990<span class="mdi mdi-currency-rub"></span></div>
                                <p class="black-txt">Рассрочка на 12 месяцев - <span class="blue">7 083<span class="mdi mdi-currency-rub"></span></span></p>
                                <button class="blue-btn">Записаться</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <h2>Что вы получите в итоге:</h2>
                        <ul class="ps-3">
                            <li class="black-txt">Ты построишь бизнес, который будет приносить от 300.000 до 1.000.000+ в месяц</li>
                            <li class="black-txt">Научишься привлекать до 150+ клиентов в месяц за счет платных и бесплатных методов </li>
                            <li class="black-txt">Научишься дорого продавать свои продукты или услуги и всегда будешь при деньгах, независимо от кризиса</li>
                            <li class="black-txt">Соберешь команду, которая будет вместо тебя стабильно приносить платежеспособных клиентов и деньги, пока ты отдыхаешь на Мальдивах</li>
                            <li class="black-txt">Масштабируешь бизнес и будешь получать от 1.000.000 до 15.000.000 прибыли в месяц стабильно</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <Reviews />
    </div>
    <Preloader v-else />
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { API_URL } from '../config'
import Preloader from '../components/ui/Preloader.vue'
import CallToAction from '../components/homepage/CallToAction.vue'
import Reviews from '../components/homepage/Reviews.vue'

export default {
    props: ["cat", "id"],
    components: {Preloader, CallToAction, Reviews},
    computed: {
        ...mapGetters({
            cats: "course/getCats"
        })
    },
    data(){
        return{
            courseItem: null,
            additions: [
                'Чек-лист “Тренировка по стилям управления»',
                'Шаблон стандартов',
                'Чек-лист контроля уборки',
                'Чек-лист мастера',
                'Чек-лист администратора',
                'Чек-лист “Вопросы для выявления ценностей”',
                'Анкета для собеседования мастера',
                'Анкета для собеседования администратора',
                'Чек-лист “Проективные вопросы»',
                'Шаблон ученического договора с сотрудником',
                'Шаблон собраний',
                'Таблица для аналитики конкурентов',
                'Чек-лист “Анализ ЦА базовый”',
                'Чек-лист «Анализ ЦА по вопросам»',
                'Таблица маркетингового планирования',
                'Платёжный календарь',
                'Таблица расчёта себестоимости и амортизации',
                'Таблица для расчёта точки безубыточности',
                'Таблица оптимальной оплаты труда',
                'Шаблон трудового договора с мастером',
                'Таблица итоговой отчётности',
                'Чек-лист «Финансовое планирование»',
                'Чек-лист «План продаж»',
                'Видео-инструкции к таблицам',
            ]
        }
    },
    watch: {
        cats(){
            this.getCatItems()
        }
    },
    created(){
        if(this.cats.length){
            this.getCatItems()
        }
    },
    methods:{
        getCatItems(){
            let singleCat = this.cats.find(item =>{
                return item.slug === this.cat
            })

            axios.post(`${API_URL}/wp-json/dms/v1/get/items/free`, {term_id: singleCat.term_id})
            .then(res =>{
                if(!res.data.length){
                    this.$router.replace('/404')
                }else{
                    this.courseItem = res.data.find(i =>{
                        return i.slug === this.id
                    })

                    console.log(this.courseItem)
                }
            })

        }
    }
}
</script>

<style scoped>
.course-card{
    background-color: #fff !important;
}
</style>