<template>
    <div>
        <section id="main">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h1>Обучение</h1>
                        <p class="big-grey">
                            Курсы, Уроки, а так же различные гайды <br> для полноценного обучения
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pb-110 pt-110">
            <div class="container">
                <div class="row">
                    <div class="tab-btns" v-if="cats.length">
                        <!-- <pre>{{cats}}</pre> -->
                        <router-link tag="button" to="/study">
                            Все
                        </router-link>
                        <router-link tag="button" :to="`/study/${item.slug}`" v-for="(item, i) in cats" :key="i">
                            {{item.name}}
                        </router-link>
                    </div>
                </div>
                <router-view></router-view>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    export default{
        computed: {
            ...mapGetters({
                cats: "course/getCats"
            })
        },
        data(){
            return{
                // cats: [
                //     {
                //         txt: 'Все',
                //         link: '/'
                //     },
                //     {
                //         txt: 'Курсы',
                //         link: '/courses'
                //     },
                //     {
                //         txt: 'Уроки',
                //         link: '/lessons'
                //     },
                //     {
                //         txt: 'Документы',
                //         link: '/documents'
                //     }
                // ],
            }
        }
    }
</script>

<style scoped>
.tab-btns .router-link-exact-active{
    border-bottom: 2px solid #316AFF !important;
}
</style>
