<template>
    <section id="login">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3">
                    <div class="login-box text-center">
                        <form @submit.prevent="login" v-if="!enterCode">
                            <h3>Вход</h3>
                            <input type="text" v-model="email" placeholder="Введите ваш e-mail"
                            :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}">
                            <p class="red-message">
                                <span v-if="$v.email.$dirty && !$v.email.required">Поле обязательно для заполнения <br></span>
                                <span v-if="$v.email.$dirty && !$v.email.email">Некорректный формат почты <br></span>
                                <span v-if="noUser">Такого пользователя нет</span>
                            </p>
                            <button class="blue-btn" type="submit">
                                <span v-if="!load">Получить код</span>
							    <span v-else><loader /></span>
                            </button>
                            <p class="small-grey">У Вас еще нет аккаунта? <br>
                                <router-link tag="a" to="/signup" class="small-blue-link">Зарегистрироваться</router-link>
                            </p>
                        </form>
                        <form @submit.prevent="submitCode" v-else>
                            <h3>Введите код</h3>
                            <input type="text" v-model="code" placeholder="Код">
                            <p class="red-message">
                                <span v-if="codeErr">Неверный код</span>
                            </p>
                            <button class="blue-btn" type="submit">
                                <span v-if="!load">Войти</span>
							    <span v-else><loader /></span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../config';
import {mapActions, mapGetters} from 'vuex'
import loader from '../components/ui/loader.vue'
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
    components: {loader},
    data(){
        return{
            email: '',
            code: '',
            noUser: false,
            compareCode: '',
            enterCode: false,
            load: false,
            codeErr: false
        }
    },
    validations: {
		phone: {
			required,
            minLength: minLength(12)
		},
		email: {
			required,
            email
		},
	},
    methods: {
        ...mapActions({
		  checkExist: "user/checkExist",
	    }),
        async login(){
            let form = {}

            if(this.$v.email.$invalid) {
				this.$v.email.$touch();
				return;
		    }
			form = {
				email: this.email
			}

            this.load = true

            await this.checkExist(form)
			.then((res)=>{
				if(res === 'Пользователь существует.'){
					this.noUser = false
					return
				}else{
					this.noUser = true
					this.load = false
				}
			})

            if(this.noUser){
				return
			}

            axios
			.post(`${API_URL}wp-json/dms/v1/send/sms/verify`, form)
			.then(res =>{
				this.compareCode = res.data.vcode
				this.enterCode = true
				this.load = false
				this.noUser = false
			})
        },
		submitCode(){

			if(this.code != this.compareCode){
				this.codeErr = true
				return
			}

			this.load = true

			this.codeErr = false

			let form = {}

			form = {
				email: this.email
			}

			axios.post(`${API_URL}wp-json/dms/v1/authorize/sms`, form)
			.then(res =>{
				if(res.data.status == 403){
					this.noUser = true
					this.load = false
					this.code = ''
					this.enterCode = false
					return
				}
				this.load = false
				this.$store.dispatch('user/VALIDATE', res.data.data)
				localStorage.setItem("user", JSON.stringify(res.data.data));

                // переход
                this.$router.replace("/profile");
				// if(res.data.data.roles.includes('contributor')){
				// 	this.$router.push({ path: '/', hash: '#tariffs' })
				// }else{
				// 	this.$router.replace("/study/settings");
				// }
				
			})
		}
    }    
}
</script>