import axios from 'axios'
import { API_URL } from '../config'

const content = {
	namespaced: true,
	state: {
        reviews: [],
        news: []
  	},
	mutations: {
		SET_REVIEWS(state, reviews){
            state.reviews = reviews
        },
        SET_NEWS(state, news){
            state.news = news
        },
	},
	actions: {
        loadReviews({commit}){
            axios.get(`${API_URL}wp-json/dms/v1/get/reviews`)
            .then(res =>{
                commit("SET_REVIEWS", res.data)
            })
        },
        loadNews({commit}){
            axios.get(`${API_URL}wp-json/dms/v1/get/posts`)
            .then(res =>{
                commit("SET_NEWS", res.data)
            })
        }        
	},
	getters: {
        getReviews(state){
            return state.reviews
        },
        getNews(state){
            return state.news
        }
	}
}

export default content


