<template>
    <section id="main">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <img src="../../assets/img/starticon.svg" class="starticon">
                    <h1 style="margin-bottom:10px;">Как построить beauty-бизнес, приносящий от</h1>
                    <h3 class="mb-36"><span style="font-size:32px; color:#316AFF;line-height:30px!important;">300.000 до 1.000.000+ <br> в месяц стабильно</span></h3>
                    <p class="big-grey">
                        Уже более 200 beauty-мастеров открыли свой бизнес под моим руководством и зарабатывают от 300.000 до 5+ млн в месяц

                    </p>
                    <button class="blue-btn">Стать учеником</button>
                </div>
            </div>
            <div class="start-img">
                <div class="mini-card" v-for="(item, i) in cards" :key="i"
                :style="`top:${item.top}px;left:${item.left}px;`">
                    <div class="mini-icon">{{item.icon}}</div>
                    <div class="title">{{item.title}}</div>
                    <div class="descr">{{item.txt}}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            cards: [
                {
                    title: 'Научим!',
                    txt: 'Не только тому, как вести бизнем, а так же как приумножать капитал',
                    icon: '💜',
                    top: '20',
                    left: '-26'
                },
                {
                    title: 'Знания',
                    txt: 'Видеокурсы, гайды, чек-листы и многое другое на нашей платформе',
                    icon: '🔥',
                    top: '595',
                    left: '-104'
                },
            ]
        }
    }
}
</script>