<template>
    <section id="action">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="video">
                        <div class="mini-card" v-for="(item, i) in cards" :key="i"
                        :style="`bottom:${item.bottom}px;left:${item.left}px;`">
                            <div class="mini-icon">{{item.icon}}</div>
                            <div class="title">{{item.title}}</div>
                            <div class="descr">{{item.txt}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <p class="big-grey mb-0">Об эксперте</p>
                    <h2>Кристина Дмитриева</h2>

                    <div class="action-list" v-for="(item, index) in lists" :key="index">
                        <img src="../../assets/img/starticon.svg" alt="">
                        <h4 v-html="item"></h4>
                    </div>
                    <br>
                    <button class="blue-btn">Стать учеником</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            lists: [
                '10 лет в управлении, 7 лет в салонном бизнесе', 
                '-Общий оборот бизнесов в месяц до 15 млн рублей', 
                'Мои ученики увеличивают доход в 3 раза за 2 месяца',
                'Вывожу бизнес с нуля до оборота в 1 млн за 6 месяцев',
                '88к подписчиков в <a href="https://vt.tiktok.com/ZSd3yRSeB/" target="_blank"> TikTok <span class="mdi mdi-arrow-right"></span></a>'],
            cards: [
                {
                    title: 'О школе',
                    txt: 'Наша онлайн-платформа подойдет, как для новичков, так и для опытных мастеров индустрии красоты',
                    icon: '🎬',
                    bottom: '-50',
                    left: '-62'
                },
            ]
        }
    }
}
</script>