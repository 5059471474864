<template>
    <section id="profile">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Персональная информация</h2>
                    <div class="login-box" style="margin-bottom:30px;padding:30px;" v-if="user">
                        <div class="row">
                            <div class="col-lg-3">
                                <label for="">Имя</label>
                                <input type="text" v-model="user.first_name">
                            </div>
                            <div class="col-lg-3">
                                <label for="">Телефон</label>
                                <input type="text" v-model="user.phone">
                            </div>
                            <div class="col-lg-3">
                                <label for="">E-mail</label>
                                <input type="text" v-model="user.user_email">
                            </div>
                            <!-- <div class="col-lg-3">
                                <button class="blue-btn" style="margin-top:27px;">Сохранить изменения</button>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <h2>Приобретенные знания</h2>
                </div>
            </div>
            <div class="row msnry-grid-item">
                <div class="col-lg-3" v-for="(course, index) in buyed" :key="index">
                    <div class="course-card">
                        <div class="course-card-img" :style="`background-image: url( ${course.image})`">
                        </div>
                    <!-- <pre>{{course}}</pre> -->
                        <div class="course-card-body">
                            <div class="info">
                                <p>{{course.category.name}}</p>
                            </div>
                            <h3>{{course.title}}</h3>
                            <div class="price-box">
                                <router-link tag="p" :to="`/course-page-access/${course.id}`" class="blue-link">
                                    К просмотру <div class="mdi mdi-arrow-right"></div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="!buyed.length">
                <div class="col-lg-3" v-for="(item, i) in 4" :key="i">
                    <Skeleton />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import {API_URL} from '../config'
// import Masonry from 'masonry-layout'
import Skeleton from '../components/ui/Skeleton.vue'

export default {
    methods: {
        changeActive(id){
            this.activeCat = id
        }
    },
    components: { Skeleton },
    computed: {
        ...mapGetters({
            user: "user/getUser",
            cats: "course/getCats"
        })
    },
    data(){
        return {
            activeCat: 'all',
            buyed: []
        }
    },
    watch: {
        cats(){
            this.loadCourses()
        }
    },
    methods: {
        loadCourses(){
            axios.post(`${API_URL}/wp-json/dms/v1/get/items`, {term_id: this.activeCat}, {
                headers: {
                    Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
                }
            })
            .then(res =>{
                res.data.forEach(item => {
                    if(this.user.znaniya.includes(item.id)){
                        this.buyed.push(item)
                    }
                })
            })
            .catch(err =>{
                if(err.response.status === 402){
                    this.$store.dispatch('user/logOut').then(() =>{
                        this.$router.replace('/enter')
                    })
                }
            })
        }
    },
    created(){
        if(this.cats.length){
            this.loadCourses()
        }
    },
    // mounted(){
    //     var msnry2 = new Masonry( '.msnry-grid-item2', {
    //     // options
    //         // horizontalOrder: true
    //     });
    // },
}
</script>

<style scoped>
.msnry-grid-item2{
    height: inherit !important;
}
</style>