<template>
  <div>
    <Main />
    <Categories />
    <!-- <Popular /> -->
    <CallToAction />
    <News />
    <Employee />
    <Reviews />
    <Getready />
  </div>
</template>

<script>
import Main from '@/components/homepage/Main.vue'
import Categories from '@/components/homepage/Categories.vue'
// import Popular from '@/components/homepage/Popular.vue'
import CallToAction from '@/components/homepage/CallToAction.vue'
import News from '@/components/homepage/News.vue'
import Employee from '@/components/homepage/Employee.vue'
import Reviews from '@/components/homepage/Reviews.vue'
import Getready from '@/components/homepage/Getready.vue'

export default {
  components: {Main, Categories, CallToAction, News, Employee, Reviews, Getready}
}
</script>
