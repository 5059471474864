import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Vuelidate from 'vuelidate'
import MarqueeText from 'vue-marquee-text-component'

import 'swiper/css/swiper.css'

const user = JSON.parse(localStorage.getItem("user"))
store.dispatch("user/VALIDATE", user);

Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper)
Vue.use(Vuelidate)
Vue.component('marquee-text', MarqueeText)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
