<template>
    <section id="login">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3">
                    <div class="login-box text-center">
                        <form @submit.prevent="signUp">
                            <h3>Регистрация</h3>
                            <input type="text" placeholder="Введите ваше имя" v-model="first_name"
                            :class="{errorInp : $v.first_name.$dirty && !$v.first_name.required}">
                            <input type="text" placeholder="Введите ваш телефон" v-model="phone" 
                            :class="{errorInp : ($v.phone.$dirty && !$v.phone.required) || ($v.phone.$dirty && !$v.phone.minLength)}">
                            <input type="text" placeholder="Введите ваш e-mail" v-model="email"
                            :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}">
                            <p class="red-message mb-20">
							<span v-if="error3">Вы уже зарегистрированы</span>
                        </p>
                            <button class="blue-btn" type="submit">
                                <span v-if="!load">Зарегистрироваться</span>
							    <span v-else><loader /></span>
                            </button>
                            <p class="small-grey">У Вас уже есть аккаунт? <br>
                                <router-link tag="a" to="/enter" class="small-blue-link"></router-link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import {mapActions, mapGetters} from 'vuex'
import loader from '../components/ui/loader.vue'

export default {
    components: {loader},
    data(){
        return{
            first_name: '',
            phone: '',
            email: '',
            load: false,
            error3: false,
        }
    },
    validations: {
		first_name: {
			required
		},
		email: {
			required,
			email
		},
		phone: {
			required,
            minLength: minLength(11)
		},
	},
    methods: {
        ...mapActions({
	        SIGN_UP: "user/SIGN_UP",
		    checkExist: "user/checkExist",
	    }),
        async signUp(){
            if(this.$v.$invalid) {
				this.$v.$touch();
				return;
		    }

            this.load = true

			let obj = {
	        	email: this.email
	     	}
            
            await this.checkExist(obj)
			.then((res)=>{
				if(res === 'Пользователь существует.'){
					this.error3 = true
					this.load = false
					return
				}else{
					this.error3 = false
				}
			})

			if(this.error3){
				return
			}

            let form = {
                first_name: this.first_name,
	        	email: this.email,
	        	phone: this.phone
	     	}

	     	await this.SIGN_UP(form).then((err) => {
				this.load = false
	     		this.$router.replace('/enter')
		    });

        }
    }
}
</script>