<template>
    <div v-if="courseItem">
        <section id="imgInner"  :style="`background-image: url( ${courseItem.image})`" >
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <p class="small-grey">{{courseItem.category.name}}</p>
                        <h2>{{courseItem.title}}</h2>
                    </div>
                </div>
            </div>
        </section>
        <VideoModal :selectedVideo="selectedVideo" v-if="selectedVideo" @closeVideo="selectedVideo = null" />
        <section class="pt-110">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>Видео</h2>
                    </div>
                    <div class="col-lg-4" v-for="(item, i) in courseItem.videos" :key="i">
                        <div class="video-box" @click="selectedVideo = item.video_fajl">
                            <div class="video-img" :style="`background-image: url( ${item.izobrazheniya})`">
                                <img src="../assets/img/play.svg" alt="">
                            </div>
                            <h3>{{item.nazvanie}}</h3>
                            <p class="small-grey">{{item.dlitelnost}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-110 pb-110">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>Документы</h2>
                    </div>
                    <div class="col-lg-4" v-for="(file, i) in courseItem.files" :key="i">
                        <div class="doc-box">
                            <span class="mdi mdi-file-link-outline"></span>
                            <p class="small-grey">{{file.fajl.title}}</p>
                            <a class="blue-link" :href="file.fajl.url" target="_blank">Скачать <div class="mdi mdi-arrow-right"></div></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Preloader v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import {API_URL} from '../config'
import VideoModal from '../components/ui/VideoModal.vue'
import Preloader from '../components/ui/Preloader.vue'

export default {
    props: ["id"],
    components: { VideoModal, Preloader },
    data(){
        return{
            selectedVideo: null,
            courseItem: null
        }
    },
    methods: {
        loadCourses(){
            axios.post(`${API_URL}/wp-json/dms/v1/get/items`, {term_id: 'all'}, {
                headers: {
                    Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
                }
            })
            .then(res =>{
                this.courseItem = res.data.find(item =>{
                    return item.id == this.id
                })
            })
            .catch(err =>{
                if(err.response.status === 402){
                    this.$store.dispatch('user/logOut').then(() =>{
                        this.$router.replace('/enter')
                    })
                }
            })
        }
    },
    computed: {
        ...mapGetters({
            user: "user/getUser",
            cats: "course/getCats"
        })
    },
    watch: {
        cats(){
            this.loadCourses()
        }
    },
    created(){
        if(this.cats.length){
            this.loadCourses()
        }
    },
}
</script>