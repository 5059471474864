<template>
    <section id="reviews">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2>Что говорят о нас <br>
                        наши студенты <br><br></h2>
                </div>
            </div>
        </div>
        <marquee-text :duration="100">
            <div class="review-cards">
                <div class="review-card" v-for="(item, index) in reviews" :key="index" v-show="item.type !== 'video'"
                    :class="index % 2 !== 0 ? 'isOdd' : ''">
                    <img :src="item.media">
                </div>
            </div>
        </marquee-text>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters({
                reviews: "content/getReviews"
            })
        }
    }
</script>