<template>
    <div>
        <section id="main">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h1>Что говорят о нас <br> наши студенты</h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="pt-110 pb-110" v-if="reviews.length">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3" style="margin-bottom:30px;" v-for="(item, index) in reviews" :key="index">
                        <div class="review-card" >
                            <img :src="item.media" v-if="item.type !== 'video'">
                            <video controls v-else>
                                <source :src="item.media" type="video/mp4" >
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section id="getready">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1 text-center">
                    <div class="get-ready-box">
                        <img src="@/assets/img/wave.png" alt="">
                        <img src="@/assets/img/wave.png" alt="">
                        <h1>Ваша оценка <br> очень важна нам </h1>
                        <div class="row">
                            <div class="col-lg-6 offset-lg-3">
                                <input type="text" placeholder="Ваше имя и фамилия">
                            </div>
                            <div class="col-lg-6 offset-lg-3">
                                <input type="text" placeholder="Оценка от 1 до 5">
                            </div>
                            <div class="col-lg-6 offset-lg-3">
                                <textarea placeholder="Отзыв" rows="7"></textarea>
                            </div>
                        </div>
                        <button class="blue-btn">Отправить</button> 
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    </div>
</template>

<script>
import Masonry from 'masonry-layout'
import { mapGetters } from 'vuex';

export default {
    mounted(){
        var msnry = new Masonry( '.msnry-grid-item', {
        // options
            // horizontalOrder: true
        });
    },
    computed: {
        ...mapGetters({
            reviews: "content/getReviews"
        })
    },
    created(){
        this.$store.dispatch('content/loadReviews')
    }
}
</script>

<style scoped>
.review-card{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.review-card video{
    width: 100%;
}
</style>