import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CoursePage from '../views/CoursePage.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Profile from '../views/Profile.vue'
import Reviews from '../views/Reviews.vue'
import CoursePageAccess from '../views/CoursePageAccess.vue'
import Study from '../views/Study.vue'
import Category from '../views/Category.vue'
import All from '../views/All.vue'
import News from '../views/News.vue'
import NewsSingle from '../views/NewsSingle.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/study',
    component: Study,
    children: [
      {
        path: '/',
        component: All
      },
      {
        path: '/study/:cat',
        component: Category,
        props: true
      }
    ]
  },
  {
    path: '/study/:cat/:id',
    component: CoursePage,
    props: true
  },
  // {
  //   path: '/course-page',
  //   component: CoursePage
  // },
  {
    path: '/reviews',
    component: Reviews
  },
  {
    path: '/news',
    component: News
  },
  {
    path: '/news/:id',
    component: NewsSingle,
    props: true
  },
  {
    path: '/enter',
    component: Login
  },
  {
    path: '/signup',
    component: Signup
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/course-page-access/:id',
    component: CoursePageAccess,
    name: 'access-page',
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }
  }
})

export default router
