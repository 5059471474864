<template>
    <div class="pop" @click="closeVideo">
        <div class="pop-box" @click.stop>
            <video id="player" playsinline controls>
                <source :src="selectedVideo" type="video/mp4" />
            </video>
        </div>
    </div>
</template>

<script>
import Plyr from 'plyr';

export default {
    props: {
        selectedVideo: {
            type: String,
            required: true
        }
    },
    mounted(){
        const player = new Plyr('#player');
    },
    methods: {
        closeVideo(){
            this.$emit('closeVideo')
        }
    }    
}
</script>