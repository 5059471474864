<template>
    <section id="cats">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2>Обучения для beauty-мастеров и руководителей</h2>
                </div>
                <!--  -->
                <div class="col-lg-4" v-for="(item, index) in cats" :key="index">
                    <div class="cat-card">
                        <img :src="item.img" alt="">
                        <h3>{{item.title}}</h3>
                        <p class="small-grey">{{item.descr}}</p>
                        <!-- <router-link tag="p" :to="item.link" class="blue-link">
                            Подробнее <div class="mdi mdi-arrow-right"></div>
                        </router-link> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 offset-lg-3" v-for="(ban, ind) in banners" :key="ind">
                    <div class="banner-card" :style="`background-image: url( ${ban.img})`">
                        <div class="type">{{ban.type}}</div>
                        <div>
                            <h3>{{ban.title}}</h3>
                            <p class="white-txt" v-html="ban.descr"></p>
                            <router-link tag="button" to="/study/kursy/kurs-marketing" class="blue-btn">Подробнее</router-link>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            banners: [
                {
                    title: 'Beauty бизнес с нуля',
                    type: 'Курс',
                    descr: 'Открой с нуля салон , который будет стабильно <br> приносить от 300.000 в месяц',
                    img: require('../../assets/img/c1.jpg')
                },
            ],
            cats: [
                {
                    title: 'Курсы',
                    descr: 'Видеокурсы, гайды, чек-листы и многое другое на нашей платформе',
                    img: require('../../assets/img/cat1.svg'),
                    link: '/study/kursy'
                },
                {
                    title: 'Видеоуроки',
                    descr: 'Видеокурсы, гайды, чек-листы и многое другое на нашей платформе',
                    img: require('../../assets/img/cat2.svg'),
                    link: '/study/korotkie-uroki'
                },
                {
                    title: 'Документы',
                    descr: 'Видеокурсы, гайды, чек-листы и многое другое на нашей платформе',
                    img: require('../../assets/img/cat3.svg'),
                    link: '/study/dokumenty'
                },
                // {
                //     title: 'Чек-листы',
                //     descr: 'Видеокурсы, гайды, чек-листы и многое другое на нашей платформе',
                //     img: require('../../assets/img/cat4.svg')
                // },
            ]
        }
    }
}
</script>