<template>
    <section id="employee">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="telka">
                        <div class="mini-card" v-for="(item, i) in cards" :key="i"
                        :style="`bottom:${item.bottom}px;right:${item.right}px;`">
                            <div class="mini-icon">{{item.icon}}</div>
                            <div class="title">{{item.title}}</div>
                            <div class="descr">{{item.txt}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="action-list">
                        <img src="../../assets/img/starticonblue.svg" alt="">
                        <h4>Присоединяйся к команде</h4>
                    </div>
                    <h2>Мы ищем опытных  <br>
                        преподавателей <br>
                        в команду</h2>
                    <p class="big-grey">Курсы для местеров и руководителей по упралению салоном в свободное от работы время.</p>
                    <button class="light-btn">Стать преподавателем</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            cards: [
                {
                    title: 'Кристина',
                    txt: 'Преподаватель со стажем, владелец сети салонов красоты',
                    icon: '✂️',
                    bottom: '-38',
                    right: '-54'
                },
            ]
        }
    }
}
</script>

<style scoped>
.action-list h4{
    color:#316AFF;
}
</style>