<template>
   <section id="news" v-if="news.length">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2>Новости и статьи</h2>
                </div>
                <div class="col-lg-4" v-for="(item, i) in news" :key="i">
                     <div class="news-card-img" :style="`background-image: url( ${item.img})`">
                        </div>
                    <div class="news-card">
                        <h4 v-html="item.name"></h4>
                        <p class="small-grey" v-html="item.content"></p>
                        <router-link tag="p" :to="`/news/${item.id}`" class="blue-link">
                            Читать далее <div class="mdi mdi-arrow-right"></div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            news: "content/getNews"
        })
    }
}
</script>

<style scoped>
#news{
    padding-top: 150px;
}
</style>