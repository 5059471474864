<template>
    <div class="course-card">
        <div class="course-card-img" :style="`background-image: url( ${course.image})`">
            <button class="blue-btn">Купить сейчас</button>
        </div>
        <div class="course-card-body">
            <div class="info">
                <p>{{course.category.name}}</p>
                <!-- <p>{{course.amount}}</p> -->
            </div>
            <h3>{{course.title}}</h3>
            <p class="small-grey">{{course.description !== '' ?  `${course.description.substr(0, 216)}...` : '' }}</p>
            <hr>
            <div class="price-box">
                <div class="price">{{course.price}} <span class="mdi mdi-currency-rub"></span></div>
                <router-link style="color:#1bc580;" tag="p" :to="`/course-page-access/${course.id}`" class="blue-link" v-if="user && user.znaniya.includes(course.id)">
                    К просмотру <div class="mdi mdi-arrow-right"></div>
                </router-link>
                <router-link tag="p" :to="`/study/${course.category.category_slug}/${course.slug}`" class="blue-link" v-else>
                    Подробнее <div class="mdi mdi-arrow-right"></div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        course: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser'
        })
    }
}
</script>