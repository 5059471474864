<template>
    <header :class="{lightHeader : this.$route.name === 'access-page'}">
        <div class="container">
            <div class="shapka">
                <div class="nav-block">
                    <router-link tag="a" to="/">
                        <img src="../../assets/img/logow.svg" class="logo" v-if="this.$route.name === 'access-page'">
                        <img src="../../assets/img/logo.svg" class="logo" v-else>
                    </router-link>
                    <ul>
                        <router-link tag="li" :to="`/${link.link}`" v-for="(link, i) in links" :key="i">
                            {{link.txt}}
                        </router-link>
                    </ul>
                </div>

                <div v-if="!user">
                    <router-link tag="button" to="/enter" class="lk-btn">Личный кабинет</router-link>
                </div>
                <div v-else>
                    <router-link tag="button" to="/profile" class="lk-btn me-2">{{user.user_email}}</router-link>
                    <button class="blue-btn" style="padding: 9px 23px;" @click="logout()">Выйти</button>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            user: "user/getUser"
        })
    },
    methods: {
        logout(){
            this.$store.dispatch("user/logOut").then(() =>{
                this.$router.replace("/enter");
            })
        }
    },
    data(){
        return{
            links: [
                {link: 'study', txt: 'Обучение'},
                {link: 'news', txt: 'Новости'},
                {link: 'about', txt: 'О школе'},
                {link: 'reviews', txt: 'Отзывы'},
                {link: 'contacts', txt: 'Контакты'}
            ]
        }
    }
}
</script>