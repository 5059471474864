import axios from 'axios'
import { API_URL } from '../config'

const course = {
	namespaced: true,
	state: {
        cats: [],
		allCards: []
  	},
	mutations: {
		SET_CATS(state, cats){
			state.cats = cats
		},
		SET_ALL_CARDS(state, allCards){
			state.allCards = allCards
		}
	},
	actions: {
		loadCats({commit}){
			axios.get(`${API_URL}/wp-json/dms/v1/get/categories`)
			.then(res =>{
				commit("SET_CATS", res.data)
			})
		},
		loadAllCards({commit}){
			axios.post(`${API_URL}/wp-json/dms/v1/get/items/free`, {term_id: 'all'})
			.then(res =>{
				commit("SET_ALL_CARDS", res.data)
			})
		}
	},
	getters: {
       	getCats(state){
			return state.cats
	   	},
		getAllCards(state){
			return state.allCards
		}
	}
}

export default course


