<template>
    <div class="row" v-if="allCards.length">
        <div class="col-lg-4" v-for="(item, index) in allCards" :key="index">
            <!-- <pre>{{item}}</pre> -->
            <CourseCard :course="item" />
        </div>
    </div>
    <div class="row" v-else>
        <div class="col-lg-4" v-for="(item, i) in 3" :key="i">
            <Skeleton />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CourseCard from '../components/ui/CourseCard.vue'
import Skeleton from '../components/ui/Skeleton.vue'

export default {
    components: {CourseCard, Skeleton},
    created(){
        this.$store.dispatch("course/loadAllCards")
    },
    computed: {
        ...mapGetters({
            allCards: "course/getAllCards"
        })
    },
    data(){
        return{
            popular:[
                {   
                    type: 'Курсы',
                    amount: '12 уроков',
                    img: require('@/assets/img/c1.jpg'),
                    name: 'Закупки и учет для салонов красоты',
                    descr: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit molestias, asperiores, ex ratione dolorem a corrupti nesciunt tempore architecto, culpa natus vitae sunt pariatur quas adipisci ea odio. ',
                    price: '10 900'
                },
                {   
                    type: 'Короткие уроки',
                    amount: '9:00',
                    img: require('@/assets/img/c2.jpg'),
                    name: 'Планировка и располажение салона',
                    descr: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit molestias, asperiores, ex ratione dolorem a corrupti nesciunt tempore architecto, culpa natus vitae sunt pariatur quas adipisci ea odio. ',
                    price: '2 990'
                },
                {   
                    type: 'Гайды',
                    amount: '4 стр.',
                    img: require('@/assets/img/c3.jpg'),
                    name: 'Профессия администратор салона красоты',
                    descr: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit molestias, asperiores, ex ratione dolorem a corrupti nesciunt tempore architecto, culpa natus vitae sunt pariatur quas adipisci ea odio. ',
                    price: '1 990'
                },
                {   
                    type: 'Курсы',
                    amount: '12 уроков',
                    img: require('@/assets/img/c4.jpg'),
                    name: 'Закупки и учет для салонов красоты',
                    descr: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit molestias, asperiores, ex ratione dolorem a corrupti nesciunt tempore architecto, culpa natus vitae sunt pariatur quas adipisci ea odio. ',
                    price: '2 500'
                },
            ]
        }
    }
}
</script>