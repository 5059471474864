import axios from 'axios'
import { API_URL } from '../config'

const user = {
	namespaced: true,
	state: {
        user: null,
  	},
	mutations: {
		SET_USER(state, user){
			state.user = user
		},
	},
	actions: {
        VALIDATE({ commit, state, dispatch }, user) {
			if (user) {
                commit("SET_USER", user)
            }
            if (!state.user) {
                return
            }
		},
		async checkExist({commit}, obj){
			try {
                const { data } = await axios.post(`${API_URL}wp-json/dms/v1/exist/user`, obj)
				return data.status
            }
            catch (err) {
            	return err.response.data.status
            }
		},
        async logOut({commit}){
			localStorage.removeItem("user");
			commit("SET_USER", null)
		},
		async SIGN_UP({commit, dispatch}, payload){
            
            try {
                const { data } = await axios.post(`${API_URL}wp-json/dms/v1/add/user`, payload)
				console.log(data)
            }
            catch (err) {
            	alert('Ошибка')
            }
        },
	},
	getters: {
        getUser(state){
			return state.user
		},
	}
}

export default user


