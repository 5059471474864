<template>
    <section id="usual">
        <div class="container" v-if="item">
            <div class="row">
                <div class="col-lg-12">
                    <div class="banner" :style="`background-image: url( ${item.img})`">
                        <h2 class="mb-0" v-html="item.name"></h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="px-5" v-html="item.content"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../config'

export default {
    props: ["id"],
    data(){
        return {
            item: null
        }
    },
    methods: {
        loadItem(){
            axios.get(`${API_URL}wp-json/dms/v1/get/posts?id=${this.id}`)
            .then(res =>{
                this.item = res.data
            })
        }
    },
    created(){
        this.loadItem()
    }
}
</script>

<style scoped>
.banner{
    height: 400px;
    border-radius: 24px;
    position: relative;
    padding: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 30px;
}
.banner::before{
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: .2;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
}
.banner h2{
    color: #fff;
    position: relative;
}
</style>