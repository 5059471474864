import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import course from './course'
import user from './user'
import content from './content'


export default new Vuex.Store({
	modules: {
		user,
		course,
		content
	}
}) 